<script lang="ts">
  import {
    fetchMessageLoading,
    sendMessageLoading,
    messageList,
    botInfo,
    apiTokenValue,
  } from "../store";
  import { afterUpdate } from "svelte";
  import MessageRowComponent from "../MessageRowComponent.svelte";
  import reloadIcon from "../assets/images/reload.icon.svg";

  import ChatForm from "../ChatForm.svelte";
  import { initializeWidget, type Message, type QuickReply } from "../service/service";
  import SkeletonView from "../SharedComponent/SkeletonView.svelte";
  import QuickReplyContainer from "../QuickReplyContainer.svelte";
  import LogoLoader from "../SharedComponent/LogoLoader.svelte";
  import { ENV } from "../config/config";
  import PinChatBoxMessageRowComponent from "./PinnedChatBoxMessageRowComponent.svelte";
  import PinnedChatBoxMessageRowComponent from "./PinnedChatBoxMessageRowComponent.svelte";
  import PinnedChatForm from "./PinnedChatForm.svelte";
  import ReloadIcon from "../SharedComponent/ReloadIcon.svelte";
  import Logo from "../Logo.svelte";
  let messageListData: Message[] = [];
  let messageContainer: HTMLDivElement | null = null;
  let conversationIsReloading: boolean = false;

  let containerRef: HTMLDivElement;
  let messagesRef: HTMLDivElement[] = [];

  let widgetIsActive: boolean = false;
  export let aiMessageConverter: any;
  export let userMessageConverter: any;

  messageList.subscribe((list) => {
    messageListData = list;
  });
  let formQuickReplyHandler: any;

  const disableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "hidden";
      widgetIsActive = true;
    }
  };

  const enableBodyScroll = () => {
    if (window !== undefined) {
      window.document.body.style.overflow = "auto";
      widgetIsActive = false;
    }
  };

  afterUpdate(() => {
    //------ scroll to bottom of the chat container ------//
    // scrollToLatest();
  });

  function scrollToBottom() {
    if (messageContainer) {
      messageContainer.scrollTo({
        top: messageContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  function scrollToView() {
    const lastMessage = messagesRef[messagesRef.length - 1];

    if (lastMessage) {
      lastMessage.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  const scrollToLatest = (): void => {
    const lastMessage = messagesRef[messagesRef.length - 1];
    if (lastMessage && containerRef) {
      // Calculate scroll position relative to container
      const containerRect = containerRef.getBoundingClientRect();
      const messageRect = lastMessage.getBoundingClientRect();
      const scrollTop = messageRect.top - containerRect.top + containerRef.scrollTop;

      containerRef.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  async function handleClickReload() {
    conversationIsReloading = true;

    localStorage.removeItem("conversationId");
    await initializeWidget($apiTokenValue);
    messageList.set([]);
    conversationIsReloading = false;
  }
  function sendQr(event: any) {
    if (formQuickReplyHandler && formQuickReplyHandler.handleQuickReply) {
      formQuickReplyHandler.handleQuickReply(event.detail.data as QuickReply);
    }
  }
</script>

<!-- on:mouseenter={disableBodyScroll}
on:mouseleave={enableBodyScroll} -->
<div role="region" aria-label="Chat Container">
  <div
    class="concierge-chat-legacy-box"
    class:concierge-chat-legacy-box-active={widgetIsActive}
    class:concierge-chat-legacy-box-inactive={!widgetIsActive}
  >
    <!-- {#if ENV === "dev"}
      <span
        class="concierge-chat-beta-label"
        class:concierge-chat-legacy-box-footer-active={widgetIsActive}
        class:concierge-chat-legacy-box-footer-inactive={!widgetIsActive}
      >
        Dev
      </span>
    {/if} -->
    <div class="concierge-chat-legacy-reload-container" class:rotating={conversationIsReloading}>
      <button
        on:click={handleClickReload}
        disabled={conversationIsReloading || $messageList.length === 0}
      >
        <ReloadIcon
          fillColor={$botInfo?.settings?.widgetAppearanceBehavior?.widgetFontColor || "#d2d2d2"}
        />
      </button>
    </div>
    <div bind:this={containerRef} class="concierge-chat-legacy-box-main-container">
      <div class="concierge-chat-legacy-box-body" bind:this={messageContainer}>
        <!-- <span style="min-height: 20px;" /> -->
        <!-- {#if !$fetchMessageLoading && $botInfo?.settings?.welcomeMessage}
          <div>
            <PinnedChatBoxMessageRowComponent
              expanded={true}
              content={$botInfo?.settings?.welcomeMessage}
              role={"assistant"}
              {aiMessageConverter}
              {userMessageConverter}
            />
          </div>
        {/if} -->

        {#each messageListData as message, i}
          <div bind:this={messagesRef[i]}>
            <PinnedChatBoxMessageRowComponent
              expanded={true}
              content={message.content}
              role={message.role}
              {aiMessageConverter}
              {userMessageConverter}
              assistantMessageId={message?.id || message?.metadata?.assistantMessageId}
              assistantFeedback={message?.assistantFeedback}
            />
          </div>
        {/each}
        <!--   {#if !$sendMessageLoading}
       <span class="concierge-ai-loader-container">
          <LogoLoader
            color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetFontColor || "#0e3c26"}
          />
        </span> 
         {/if}
        -->
        {#if $sendMessageLoading}
          <!-- <span class="concierge-ai-loader-container">
          <LogoLoader
            color={$botInfo?.settings?.widgetAppearanceBehavior?.widgetFontColor || "#0e3c26"}
          />
        </span> -->
          <span class="concierge-ai-dot-loader-container">
            <span
              class="concierge-ai-dot-loader-bot-icon"
              style="--assistant-stroke-color: {$botInfo?.settings?.widgetAppearanceBehavior
                ?.widgetAssistantAvatarStrokeColor || '#eaeaea'};"
            >
              {#if $botInfo?.settings?.botImageUrl}
                <img src={$botInfo?.settings?.botImageUrl} alt="logo" />
              {:else}
                <Logo strokeColor={"#0e3c26"} width={"26"} height={"26"} />
              {/if}
            </span>
            <span
              class="concierge-ai-dot-loader-dot"
              style="--loader-color: {$botInfo?.settings?.widgetAppearanceBehavior
                ?.widgetFontColor || '#d2d2d2'};"
            ></span>
          </span>
        {/if}
      </div>
    </div>
    <PinnedChatForm
      on:messageReceived={scrollToLatest}
      bind:this={formQuickReplyHandler}
      expanded={false}
    />
    <div class="concierge-chat-legacy-box-footer">
      <span>
        Powered by <a href="https://webconcierge.ai" target="_blank">Concierge</a>
      </span>
    </div>
  </div>
</div>

<style>
  .concierge-chat-legacy-box {
    margin: 10px auto;
    margin-top: 10px;
    /* background-color: #ffffff; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    /* box-shadow: 0px 2px 12px 0px #00000033; */
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .concierge-chat-legacy-box {
      height: 225px;
      margin-left: 20px; /* This would be about 20px */
      margin-right: 20px; /* This would be about 15px */
      width: calc(100% - 40px);
    }
  }
  @media (min-width: 991px) {
    .concierge-chat-legacy-box {
      height: 250px;
    }
  }

  @media (max-width: 767px) {
    .concierge-chat-legacy-box {
      margin-left: 5px; /* This would be about 20px */
      margin-right: 5px; /* This would be about 15px */
      width: calc(100% - 10px);
    }
  }

  /*-------------------------*/
  /*------- chat body -------*/
  /*-------------------------*/

  .concierge-chat-legacy-box-main-container {
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
    overflow: scroll; /* Enables scrolling */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    margin: 10px;
  }

  @media (max-width: 767px) {
    .concierge-chat-legacy-box-main-container {
      margin: 5px 0px;
    }
  }

  .concierge-chat-legacy-box-main-container::-webkit-scrollbar {
    display: none;
  }
  .concierge-chat-legacy-reload-container {
    position: absolute;
    display: flex;
    right: 10px;
    top: 10px;
    opacity: 0.5;
    transition:
      transform 1s,
      opacity 0.3s ease;
    z-index: 10;
  }

  .concierge-chat-legacy-reload-container:hover {
    opacity: 1;
  }
  .concierge-chat-legacy-reload-container > button {
    background-color: var(--element-background-color);
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotate 2s linear infinite;
  }
  .concierge-chat-legacy-reload-container > button {
    border: none;
    outline: none;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .concierge-chat-legacy-reload-container > button:disabled {
    cursor: not-allowed;
  }
  /* .concierge-chat-legacy-box-main-container::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;  
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;  
  } */

  .concierge-chat-legacy-box-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    .concierge-chat-legacy-box-body {
      gap: 25px;
    }
  }
  @media (max-width: 767px) {
    .concierge-chat-legacy-box-body {
      gap: 20px;
    }
  }

  .concierge-chat-legacy-box-body > div {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    /* border-bottom: 1px solid #eeeeee; */
  }

  /* .concierge-chat-legacy-box-footer-active {
    opacity: 1;
  }
  .concierge-chat-legacy-box-footer-inactive {
    opacity: 0;
  } */

  /* .concierge-ai-loader-container {
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 5px 0px 5px 10px;
  } */

  .concierge-ai-dot-loader-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    /* margin: 5px; */
    gap: 10px;
    /* padding: 10px; */
  }

  .concierge-ai-dot-loader-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--loader-color);
    animation: dot-loader-pulse 2s infinite ease-in-out;
  }

  @media (max-width: 600px) {
    .concierge-ai-dot-loader-dot {
      width: 10px;
      height: 10px;
    }
  }
  @keyframes dot-loader-pulse {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.75);
    }
  }

  .concierge-ai-dot-loader-bot-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: var(--assistant-stroke-color);
    background-color: transparent;

    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .concierge-ai-dot-loader-bot-icon img {
    width: 30px;
    height: 30px;
    padding: 1px;
  }
  .concierge-chat-legacy-box-footer {
    /* position: absolute;
    bottom: -10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
    transition:
      height 0.3s ease,
      opacity 0.3s ease;
  }

  .concierge-chat-legacy-box-footer > span {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: #b1b1b1;
  }

  .concierge-chat-legacy-box-footer > span > a {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    color: #b1b1b1;
    /* text-decoration: unset; */

    font-weight: bold;
  }
</style>
